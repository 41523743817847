<template>
  <CRow v-if="dataAvailable" class="h-100">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <div class="emoji_cloud_container d-flex align-center h-100">
        <wordcloud :data="emojiData" nameKey="unicode" valueKey="count" :fontSize="[20,55]" :wordPadding="5" :rotate="{from: -25, to: 25, numOfOrientation: 5 }" :showTooltip="false" :wordClick="wordClick"></wordcloud>
      </div>
    </CCol>
  </CRow>
  <CRow v-else class="h-100">
    <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
      <div class="h-100 w-100 d-flex align-items-center justify-content-center">
        <span class="text-center">{{ $t('sq.not_enough_emoji_cloud_data') }}</span>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import wordcloud from 'vue-wordcloud'

export default {
  name: 'emojiCloud',
  props: ['emojiCloud'],
  components: {
    wordcloud
  },
  watch: {
    $props: {
      handler() {
        if(this.emojiCloud.data_available) {
          this.emojiData = this.emojiCloud.emoji_data;
          this.dataAvailable = true;
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      dataAvailable: false,   
      emojiData: []   
    }
  },
  methods: {
    wordClick(name, value, vm) {
      console.log('wordClickHandler', name, value, vm);
    }
  }
}
</script>