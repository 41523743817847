<template>
  <CRow v-if="dataAvailable" class="nps">
    <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
      <div class="w-100 d-flex align-items-center justify-content-center">
        <CChartDoughnut class="chart d-inline-block mt-2 mb-2" :datasets="npsChartData.datasets" :labels="npsChartData.labels" :options="npsChartData.options"/>
        <div class="score chart_score position-absolute">
          <span class="font-weight-bold" v-bind:class="{above : npsData.score > 0, below : npsData.score < 0}">{{npsData.score}}</span>
        </div>
      </div>
    </CCol>
    <CCol cols="4" xl="4" md="4" sm="4" class="text-center promoters pt-0 pb-0">
      <div class="percentage">
        <span>{{npsData.promoters.percentage}}%</span><i class="icon fas fa-smile"/>
      </div>
      <div>
        <span>{{ $t('sq.nps_promoters') }}</span>
        <!-- <span class="ml-1">({{npsData.promoters.amount}})</span> -->
      </div>
      <div v-if="npsData.promoters.growth !== 0" class="score" v-bind:class="{increased : npsData.promoters.growth > 0, decreased : npsData.promoters.growth < 0}">
        <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': npsData.promoters.growth > 0, 'fa-arrow-circle-down': npsData.promoters.growth < 0}"/>
        <span class="growth">{{npsData.promoters.growth !== 0 ? npsData.promoters.growth + "%" : '-' }}</span>
      </div>
    </CCol>

    <CCol cols="4" xl="4" md="4" sm="4" class="text-center passives pt-0 pb-0">
      <div class="percentage">
        <span>{{npsData.passives.percentage}}%</span><i class="icon fas fa-meh"/>
      </div>
      <div>
        <span>{{ $t('sq.nps_passives') }}</span>
        <!-- <span class="ml-1">({{npsData.passives.amount}})</span> -->
      </div>
      <div v-if="npsData.passives.growth !== 0" class="score" v-bind:class="{increased : npsData.passives.growth > 0, decreased : npsData.passives.growth < 0}">
        <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': npsData.passives.growth > 0, 'fa-arrow-circle-down': npsData.passives.growth < 0}"/>
        <span class="growth">{{npsData.passives.growth !== 0 ? npsData.passives.growth + "%" : '-' }}</span>
      </div>                
    </CCol>

    <CCol cols="4" xl="4" md="4" sm="4" class="text-center detractors pt-0 pb-0">
      <div class="percentage">
        <span>{{npsData.detractors.percentage}}%</span><i class="icon fas fa-frown"/>
      </div>
      <div>
        <span>{{ $t('sq.nps_detractors') }}</span>
        <!-- <span class="ml-1">({{npsData.detractors.amount}})</span> -->
      </div>
      <div v-if="npsData.detractors.growth !== 0" class="score" v-bind:class="{increased : npsData.detractors.growth > 0, decreased : npsData.detractors.growth < 0}">
        <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': npsData.detractors.growth > 0, 'fa-arrow-circle-down': npsData.detractors.growth < 0}"/>
        <span class="growth">{{npsData.detractors.growth !== 0 ? npsData.detractors.growth + "%" : '-' }}</span>
      </div>                
    </CCol>
  </CRow>
  <CRow v-else class="h-100">
    <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
      <div class="h-100 w-100 d-flex align-items-center justify-content-center">
        <span class="text-center">{{ $t('sq.not_enough_nps_data') }}</span>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  name: 'NetPromoterScore',
  props: ['enps'],
  components: {
    CChartDoughnut
  },
  watch: {
    $props: {
      handler() {
        if(this.enps.data_available) {
          this.setNPSData();
        }  
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      dataAvailable: false,
      npsData: {},
      npsChartData: {        
        datasets: [],
        labels: [],
        options: {
          responsive: false,
          maintainAspectRatio: true,
          legend: {display: false},
          tooltips: {enabled: false},
          hover: {mode: null},
          aspectRatio: 1
        }    
      }   
    }
  },
  methods: {
    setNPSData() { 
      this.npsData = this.enps;     
      // Set the score
      this.npsData.score = this.npsData.promoters.percentage - this.npsData.detractors.percentage;
      // Setup NPS chart
      this.npsChartData.labels = [this.$t('sq.nps_promoters'), this.$t('sq.nps_passives'), this.$t('sq.nps_detractors')];
      this.npsChartData.datasets = [{
        backgroundColor: [
          getComputedStyle(document.documentElement).getPropertyValue('--DashboardLightGreen'),
          getComputedStyle(document.documentElement).getPropertyValue('--DashboardLightGray'),
          getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary')       
        ],
        data: [this.npsData.promoters.amount, this.npsData.passives.amount, this.npsData.detractors.amount]
      }];
      // Update the dataAvailable value
      this.dataAvailable = true;
    }
  } 
}
</script>